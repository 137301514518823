import * as Yup from 'yup';

export const emailSchema = Yup.string()
  .email()
  .required()
  .default('')
  .test(
    'no-edu-gov-domains',
    'Enter a permanent email for application login access - Avoid school, work, or temporary emails.',
    (value) => {
      if (!value) return true;
      const domain = value.split('@')[1]?.toLowerCase();
      // Reject any domain containing .edu or .gov, or starting with edu or gov
      return !domain?.includes('.edu') && 
             !domain?.includes('.gov');
    }
  );
